import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'


import lazyload from '@area17/a17-lazyload';
import Splide from '@splidejs/splide';
import {Grid} from '@splidejs/splide-extension-grid';
import WOW from 'wow.js';
import ProgressBar from 'progressbar.js';

window._ = require('lodash');
window.$ = window.jQuery = require('jquery');

require('jquery.appear');
require('jquery-circle-progress');

Alpine.plugin(intersect)
window.Alpine = Alpine
window.ProgressBar = ProgressBar
window.axios = require('axios');
document.addEventListener('alpine:init', () => {
    Alpine.data('circleProgress', () => ({
        open: false,

        inited() {
            const cirpro = $("#trusted_by");
            const temp = parseInt(cirpro.attr('data-value'));
            if (!this.open) {
                cirpro
                    .circleProgress({
                        value: temp / 100,
                        fill: {
                            gradient: ["#019aab", "#01c19a", "#0060c4"]
                        },
                        emptyFill: "#40464A",
                        startAngle: -Math.PI / 2,
                        thickness: 25
                    })
                    .on("circle-animation-progress", function (event, progress) {
                        $(this)
                            .find(".trusted_number")
                            .html(parseInt(progress * temp) + "%");
                    });
                this.open = true;
            }
        }
    }))
})
Alpine.start()
window.addEventListener('DOMContentLoaded', function () {
    lazyload();

    new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: false,
        live: true
    }).init();
    if (document.querySelector('.splide')) {
        var elms = document.getElementsByClassName('splide');
        for (var i = 0, len = elms.length; i < len; i++) {
            new Splide(elms[i], {
                'type': 'fade',
                'arrows': false,
                'pagination': false,
            }).mount({Grid});
        }
    }

});
